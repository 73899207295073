import { ApolloQueryResult } from 'apollo-client';
import { AppSyncResponse } from '@helpers/appSyncResponse.helper';

export class Employee {
    public id: string;
    public name: string;

    constructor(employee: Employee) {
        this.id = employee.id;
        this.name = employee.name;
    }

    public static deserializeAppSyncResponse(appSyncResponse: ApolloQueryResult<AppSyncResponse>): Employee[] {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: Employee[]) => obj.map((e: Employee) => e), [
            'getEmployeeList',
            'employeeList',
        ]);
    }
}
