import { SelectDateTimeService } from './../select-date-time.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'custom-calendar-header',
    styleUrls: ['custom-calendar-header.component.scss'],
    templateUrl: 'custom-calendar-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCalendarHeaderComponent<D> implements OnInit, OnDestroy {
    private _destroyed = new Subject<void>();
    @Output() isNextClicked = new EventEmitter<string>();

    constructor(
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef,
        private selectDateTimeService: SelectDateTimeService
    ) {
        _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
    }

    ngOnInit() {
        /* istanbul ignore next */
        this.selectDateTimeService.onCalendarDataChanged.subscribe(() => {
            this._calendar.updateTodaysDate();
        });
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    /* istanbul ignore next */
    refreshCalendar() {
        this._calendar.updateTodaysDate();
    }

    get periodLabel() {
        return this._dateAdapter.format(this._calendar.activeDate, this._dateFormats.display.monthYearA11yLabel);
    }

    previousClicked() {
        this.selectDateTimeService.previousClicked();
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
    }

    /**
     * Executes the logic that checks if the left chevron in the calendar should be disabled or not
     * @returns A boolean value that controls if the left chevron in the calendar should be disabled or not
     */
    previousEnabled() {
        const activeDay = this._dateAdapter.today();
        const newActiveDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
        const activeDayDateObj = new Date(activeDay as unknown as Date);
        const newActiveDateObj = new Date(newActiveDate as unknown as Date);
        if (
            newActiveDateObj.getFullYear() > activeDayDateObj.getFullYear() ||
            (newActiveDateObj.getFullYear() === activeDayDateObj.getFullYear() &&
                newActiveDateObj.getMonth() >= activeDayDateObj.getMonth())
        ) {
            return false;
        }
        return true;
    }

    nextClicked() {
        this.selectDateTimeService.nextClicked();
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
    }
}
