export const environment = {
    production: false,
    env: 'stage',
    region: 'us-east-2',
    identityPoolID: 'us-east-2:e2414489-5e2a-4b54-a05e-8abc27492bf9',
    serviceUrl: 'https://6edx5etzincnlgp6le3rgukibi.appsync-api.us-east-2.amazonaws.com/graphql',
    calendarAvailabilitySearchThreshold: 1000,
    dsbNewRelicLicenseKey: '',
    dsbNewRelicApplicationID: '',
    dsbNewRelicAccountID: '',
    dsbNewRelicTrustKey: '',
};
