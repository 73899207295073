import { LocalStorageService, CUSTOMER_ID } from '@app/services/local-storage.service';
import { Injectable } from '@angular/core';

export interface TraceObject {
    name: string;
    start: number;
    end?: number;
    origin?: string;
    type?: string;
}

export interface AdditionalErrorInfo {
    additionalErrorInfo: any;
}

declare let newrelic: any;
declare let require: any;
const { version: appVersion } = require('../../../../package.json');

@Injectable({
    providedIn: 'root',
})
export class NewRelicLoggingService {
    constructor(private localStorageService: LocalStorageService) {}

    /**
        @see
        Newrelic Browser => Page Views
        OR
        NRQL command like the following:
            SELECT * FROM PageAction
    */
    addPageAction(pageAction: any, actionName?: string) {
        try {
            pageAction.customerId = this.localStorageService.getString(CUSTOMER_ID);
            pageAction.appBuild = appVersion;

            newrelic.addPageAction(actionName, pageAction);
        } catch (err) {
            console.error(err);
        }
    }

    /**
        @see
        Newrelic Browser => Session Traces
    */
    addToTrace(object: TraceObject) {
        try {
            newrelic.addToTrace(object);
        } catch (err) {
            console.error(err);
        }
    }

    /**
        @see
        How to see the PageAction:
            NRQL command like the following:
                SELECT * FROM PageAction WHWRE Error IS NOT NULL

        How to see the noticeError:
            Newrelic Browser  => JS Error

        How to see the trace:
            See the comment above the addToTrace method
    */
    logError(error: any, pageOrService: string, actionName?: string, additionalErrorInfo?: AdditionalErrorInfo) {
        try {
            const pageAction = {
                pageOrService: pageOrService,
                error: error.message,
            };

            let errorObj: any = error;

            if (!error.message) {
                if (error.errorMessage) {
                    pageAction.error = error.errorMessage;
                } else {
                    pageAction.error = error;
                }

                errorObj = new Error(pageAction.error);
            }

            this.addPageAction(pageAction, actionName);

            if (additionalErrorInfo) {
                newrelic.noticeError(errorObj, { ...additionalErrorInfo });
            } else {
                newrelic.noticeError(errorObj);
            }

            this.addToTrace({
                name: error.message,
                start: Date.now(),
            });
        } catch (err) {
            console.error(err);
        }
    }
}
