export class AppSyncResponse {
    [key: string]: any;

    /** Returns the parsed AppSync response
     *
     * @remarks
     * This method drills down the nested properties in the AppSync response object
     * and parses the object on the requested level using the parser function that is
     * passed as an argument to the method and the list of nested object keys that
     * are used to specify the depth.
     *
     * @param {AppSyncResponse} response - The AppSync response object (of type AppSyncResponse)
     * This object is indexed with the 'data' key inside of the ApolloQueryResult object.
     * @param {Function} parserFunction - The function that will be used to parse the requested nested object
     * from the response param.
     * @param {string[]} orderedNestedPath - The ordered list of the keys that make up the nested hierarchy
     * and the path to the object that needs to be parsed in the response param.
     */
    public static parseResponse<Type>(response: AppSyncResponse, parserFunction: Function, orderedNestedPath: string[]): Type {
        if (!orderedNestedPath || !orderedNestedPath.length) {
            return parserFunction(response);
        }

        const responseToParse = response[orderedNestedPath[0]];
        orderedNestedPath.shift();
        return this.parseResponse(responseToParse, parserFunction, orderedNestedPath);
    }
}
