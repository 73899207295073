import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Availability } from '@app/models/availability.model';
import { AvailabilitiesService } from '@app/services/availabilities.service';
import { convertTo12Hour, convertTo24Hour, dateToStringNoTimeZone } from '@app/shared/helpers/extensions';
import { BehaviorSubject, from, Subject, Subscription, takeUntil } from 'rxjs';
import { NavigationService, ScreenToShow } from '../../../services/navigation.service';
import { LocalDbService, LOCAL_DB_TABLE_NAME } from './../../../services/local-db.service';
import { APPOINTMENT_REQUEST, LocalStorageService } from './../../../services/local-storage.service';
import { CustomCalendarHeaderComponent } from './custom-calendar-header/custom-calendar-header.component';
import { SelectDateTimeService } from './select-date-time.service';

export enum DateTimeScreen {
    None = 'None',
    DateScreen = 'DateScreen',
    TimeScreen = 'TimeScreen',
}

@Component({
    selector: 'app-select-date-time',
    templateUrl: './select-date-time.component.html',
    styleUrls: ['./select-date-time.component.scss'],
    providers: [SelectDateTimeService],
})
export class SelectDateTimeComponent implements OnInit, OnDestroy {
    DateTimeScreen = DateTimeScreen;
    public selected = new Date();
    public current = new Date();
    public dateTimeScreen: DateTimeScreen = DateTimeScreen.None;
    public month = '';
    morningTimeSlots: string[] = [];
    afternoonTimeSlots: string[] = [];
    public minDate = new Date();
    public availabilities: BehaviorSubject<Availability[]> = new BehaviorSubject<Availability[]>([]);
    private _destroyed$: Subject<void>;
    public isLoading = false;
    public customCalendarHeaderComponent = CustomCalendarHeaderComponent;
    private subscriptions: Subscription[] = [];
    public ngUnsubscribe: Subject<any> = new Subject();
    public isForwardButtonDisabled: boolean = false;
    public isBackButtonDisabled: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private navigationService: NavigationService,
        private availabilitiesService: AvailabilitiesService,
        private selectDateTimeService: SelectDateTimeService,
        private localStorageService: LocalStorageService,
        private localDbCache: LocalDbService
    ) {
        this.dateTimeScreen =
            this.navigationService.showedScreen === ScreenToShow.ScreenTwo ? DateTimeScreen.TimeScreen : DateTimeScreen.DateScreen;
        this._destroyed$ = new Subject();
    }

    ngOnInit() {
        /* istanbul ignore next */
        this.localDbCache.remove(LOCAL_DB_TABLE_NAME.availabilities, 'availabilitiesList');
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        if (appointmentRequest && appointmentRequest.datetime) {
            this.updateCurrent({ current: new Date(appointmentRequest.datetime) });
        }
        this.subscriptions.push(
            this.navigationService.getNavigationBetweenDateAndTime().subscribe((screenToShow) => {
                this.dateTimeScreen = screenToShow === ScreenToShow.ScreenTwo ? DateTimeScreen.TimeScreen : DateTimeScreen.DateScreen;
                this.localStorageService.setNested(APPOINTMENT_REQUEST, 'datetime', dateToStringNoTimeZone(this.selected));
            })
        );

        this.fetchAvailabilities({
            endDate: this.selectDateTimeService.getEndOfMonth(this.selectDateTimeService.getNextMonth(this.current)),
            startDate: this.selectDateTimeService.getPreviousMonth(this.selectDateTimeService.getNextMonth(this.current)),
        });
        /* istanbul ignore next */
        this.subscriptions.push(
            this.selectDateTimeService.nextMonthClicked.subscribe(() => {
                this.nextMonthClicked();
            })
        );
        /* istanbul ignore next */
        this.subscriptions.push(
            this.selectDateTimeService.previousMonthClicked.subscribe(() => {
                this.previousMonthClicked();
            })
        );
    }

    /* istanbul ignore next */
    timeSlotCreate(timeSlot: string) {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;

        if (appointmentRequest && appointmentRequest.datetime) {
            let partOfDayIndex;
            let partOfDay: string;
            const timeInDay = convertTo12Hour((appointmentRequest.datetime as unknown as string).split('T')[1], true);
            if (timeInDay && timeInDay.includes('AM')) {
                partOfDay = 'ts-morning-';
                partOfDayIndex = this.morningTimeSlots.findIndex((t) => t === timeInDay);
            } else {
                partOfDay = 'ts-afternoon-';
                partOfDayIndex = this.afternoonTimeSlots.findIndex((t) => t === timeInDay);
            }

            if (timeInDay && timeInDay === timeSlot) {
                const selectedTimeSlot = this.elementRef.nativeElement.querySelector(`#${partOfDay}${partOfDayIndex}`);
                if (selectedTimeSlot) {
                    selectedTimeSlot.classList.add('active');
                }
            }
            this.setStepValidState();
        }
        return timeSlot;
    }

    /* istanbul ignore next */
    setStepValidState() {
        const selectedTimeSlot = this.elementRef.nativeElement.querySelector('.active');

        if (this.dateTimeScreen === DateTimeScreen.TimeScreen) {
            if (!selectedTimeSlot) {
                this.navigationService.setIsStepFormValid(false);
            } else {
                this.navigationService.setIsStepFormValid(true);
            }
        }
    }

    /**
     * Handles the click event on the right chevron (moving to the next month)
     */
    nextMonthClicked() {
        this.current = this.selectDateTimeService.getNextMonth(this.current);
        this.fetchAvailabilities({
            endDate: this.selectDateTimeService.getEndOfMonth(this.selectDateTimeService.getNextMonth(this.current)),
        });
    }

    /**
     * Handles the click event on the left chevron (moving to the previous month)
     */
    previousMonthClicked() {
        this.current = this.selectDateTimeService.getPreviousMonth(this.current);
        this.fetchAvailabilities({
            endDate: this.selectDateTimeService.getEndOfMonth(this.selectDateTimeService.getNextMonth(this.current)),
        });
    }

    /**
     * Updates the current selected date in the calendar
     * @param eventData An object carrying the current selected date on the calendar
     */
    /* istanbul ignore next */
    updateCurrent(eventData: { current: Date }) {
        if (eventData && eventData.current) {
            this.current.setFullYear(eventData.current.getFullYear());
            this.current.setMonth(eventData.current.getMonth());
            this.current.setDate(eventData.current.getDate());
            this.current.setHours(eventData.current.getHours());
            this.current.setMinutes(eventData.current.getMinutes());
            this.current.setSeconds(eventData.current.getSeconds());
            this.current.setMilliseconds(eventData.current.getMilliseconds());
            this.selected.setFullYear(eventData.current.getFullYear());
            this.selected.setMonth(eventData.current.getMonth());
            this.selected.setDate(eventData.current.getDate());
            this.selected.setHours(eventData.current.getHours());
            this.selected.setMinutes(eventData.current.getMinutes());
            this.selected.setSeconds(eventData.current.getSeconds());
            this.selected.setMilliseconds(eventData.current.getMilliseconds());
            this.setNewAvailabilityTimes();
            this.localStorageService.setNested(APPOINTMENT_REQUEST, 'datetime', dateToStringNoTimeZone(this.selected));
            this.setStepValidState();
        }
    }

    /**
     * Updates the isLoading property that controls the screen loader
     * @param eventData An object carrying the indicator if the loader should be enabled or disabled
     */
    toggleIsLoading(eventData: { isLoading: boolean }) {
        if (eventData && eventData.isLoading !== undefined) {
            this.isLoading = eventData.isLoading;
        }
    }

    /**
     * Fetches the availabilities from the cache or the API (if the data is not present in the cache)
     * @param eventData An object carrying the data needed for the availabilities query
     */
    fetchAvailabilities(eventData: { endDate: Date; startDate?: Date }) {
        this.isLoading = true;
        const showSpinner = this.navigationService.getScreenToShow() !== ScreenToShow.ScreenTwo;
        if (eventData.startDate) {
            this.current = new Date(eventData.startDate);
        }

        if (this.current < this.minDate) {
            this.current = new Date(this.minDate);
        }

        from(
            this.availabilitiesService.getAvailabilities(
                this.selectDateTimeService.getDateString(this.current),
                this.selectDateTimeService.getDateString(eventData.endDate),
                showSpinner
            )
        )
            .pipe(takeUntil(this._destroyed$))
            .subscribe((avaibilities) => {
                this.availabilities.next(avaibilities);
                this.isLoading = false;

                /* istanbul ignore next */
                if (
                    !avaibilities.filter((a) => a.start.includes(this.selectDateTimeService.getDateString(this.selected).split('T')[0]))
                        .length
                ) {
                    this.selected = new Date(avaibilities[0].start);
                }
                this.navigationService.setIsStepFormValid(true);
            });
    }

    /**
     * Checks to see if the date to check should be enabled or disabled in the calendar.
     * The function is called for every day in the current month on calendar load.
     * @param date Date to check
     * @returns A boolean indicator if the date to check should be enabled or disabled in the calendar
     */
    filterDates = (date: any): boolean => {
        if (!date) {
            return false;
        }
        date = this.selectDateTimeService.getDateString(new Date(date.toString()));
        const availabilityFound = this.availabilities.getValue().filter((a) => a.start.includes(date.split('T')[0]));
        return !!availabilityFound.length;
    };

    /* istanbul ignore next */
    onFocus(partOfDay: string, id: number) {
        const previousSelectedTimeSlot = this.elementRef.nativeElement.querySelector('.active');
        const selectedTimeSlot = this.elementRef.nativeElement.querySelector(`#${partOfDay}${id}`);
        if (previousSelectedTimeSlot) {
            previousSelectedTimeSlot.classList.remove('active');
        }
        let newTimeParts: string[] = [];
        if (partOfDay.includes('morning')) {
            newTimeParts = convertTo24Hour(this.morningTimeSlots[id]).split(':');
        } else if (partOfDay.includes('afternoon')) {
            newTimeParts = convertTo24Hour(this.afternoonTimeSlots[id]).split(':');
        }
        this.selected.setHours(+newTimeParts[0], +newTimeParts[1]);

        this.localStorageService.setNested(APPOINTMENT_REQUEST, 'datetime', dateToStringNoTimeZone(this.selected));
        selectedTimeSlot.classList.add('active');
        this.setStepValidState();
    }

    public setNewAvailabilityTimes() {
        this.morningTimeSlots = [];
        this.afternoonTimeSlots = [];

        const timesInDay = this.availabilities
            .getValue()
            .filter((a) => a.start.includes(this.selectDateTimeService.getDateString(this.selected).split('T')[0]));

        if (timesInDay) {
            timesInDay.forEach((availability) => {
                const time = availability.start.slice(availability.start.indexOf('T') + 1);
                const timeParts = time.split(':');

                timeParts[1] = timeParts[1] + ' ' + (parseInt(timeParts[0]) < 12 ? 'AM' : 'PM');
                timeParts[0] = (parseInt(timeParts[0]) % 12 || 12).toString();
                const newTime = timeParts[0] + ':' + timeParts[1];
                if (timeParts[1].includes('AM')) {
                    this.morningTimeSlots.push(newTime);
                } else {
                    this.afternoonTimeSlots.push(newTime);
                }
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.subscriptions = [];
        this._destroyed$.next();
        this._destroyed$.complete();
        this.ngUnsubscribe.complete();
    }
}
