import { Injectable } from '@angular/core';
import { PetApiService } from './api/pet-api.service';

@Injectable({
    providedIn: 'root',
})
export class PetService {
    constructor(private petApiService: PetApiService) {}

    public getPetTypeList() {
        return this.petApiService.getPetTypeList();
    }
}
