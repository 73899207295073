import { AppSyncResponse } from '@helpers/appSyncResponse.helper';
import { ApolloQueryResult } from 'apollo-client';

export class SiteBookingSettings {
    public businessName: string;
    public businessPhoneNumber: string;
    public businessAddress: string;
    public themeColor: string;

    constructor(settings: SiteBookingSettings) {
        this.businessAddress = settings.businessAddress;
        this.businessName = settings.businessName;
        this.businessPhoneNumber = settings.businessPhoneNumber;
        this.themeColor = settings.themeColor;
    }

    public static deserializeAppSyncResponse(appSyncResponse: ApolloQueryResult<AppSyncResponse>): SiteBookingSettings {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: SiteBookingSettings) => obj, [
            'getSiteBookingSettings',
            'siteBookingSettings',
        ]);
    }
}
