import { Injectable } from '@angular/core';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { environment } from '@environments/environment';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { AppSyncService } from '../app-sync.service';

@Injectable({
    providedIn: 'root',
})
export class SiteApiService {
    constructor(private appSyncService: AppSyncService) {}

    async getSiteBookingSettings(daySmartAccountID: string) {
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getSiteBookingSettingsQuery = gql`
            query getSiteBookingSettings($daySmartAccountID: ID!) {
                getSiteBookingSettings(input: { daySmartAccountID: $daySmartAccountID }) {
                    siteBookingSettings {
                        businessAddress
                        businessName
                        businessPhoneNumber
                        themeColor
                    }
                }
            }
        `;
        const queryResult: ApolloQueryResult<AppSyncResponse> = await client.query({
            query: getSiteBookingSettingsQuery,
            variables: { daySmartAccountID },
            fetchPolicy: 'no-cache',
        });

        const model = SiteBookingSettings.deserializeAppSyncResponse(queryResult);
        return model;
    }

    async getSite(daySmartAccountID: string): Promise<boolean> {
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getSiteQuery = gql`
            query getSiteByDaySmartAccountID($daySmartAccountID: ID!) {
                getSiteByDaySmartAccountID(input: { daySmartAccountID: $daySmartAccountID }) {
                    supportsPets
                }
            }
        `;
        const queryResult: ApolloQueryResult<AppSyncResponse> = await client.query({
            query: getSiteQuery,
            variables: { daySmartAccountID },
            fetchPolicy: 'no-cache',
        });

        return queryResult.data['getSiteByDaySmartAccountID']['supportsPets'] as boolean;
    }
}
