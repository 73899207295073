import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ScreenToShow {
    ScreenOne = 'ScreenOne',
    ScreenTwo = 'ScreenTwo',
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private navigationBetweenDateAndTime = new Subject<string>();
    private buttonName = new Subject<string>();
    public showedScreen!: ScreenToShow;
    public isStepValid = new Subject<boolean>();
    public isLoading = new Subject<boolean>();

    constructor() {}

    setNavigationBetweenDateAndTime(screenToShow: ScreenToShow) {
        this.showedScreen = screenToShow;
        this.navigationBetweenDateAndTime.next(screenToShow);
    }
    getNavigationBetweenDateAndTime() {
        return this.navigationBetweenDateAndTime.asObservable();
    }

    getScreenToShow() {
        return this.showedScreen;
    }

    setNavigationButtonName(buttonName: string) {
        this.buttonName.next(buttonName);
    }
    getNavigationButtonName() {
        return this.buttonName.asObservable();
    }

    setIsStepFormValid(isValid: boolean) {
        this.isStepValid.next(isValid);
    }

    getIsStepFormValid() {
        return this.isStepValid.asObservable();
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading.next(isLoading);
    }

    getIsLoading() {
        return this.isLoading.asObservable();
    }
}
