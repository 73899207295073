import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Availability } from '@app/models/availability.model';
import { AvailabilitiesService } from '@app/services/availabilities.service';
import { APPOINTMENT_REQUEST, LocalStorageService } from '@app/services/local-storage.service';
import { environment } from '@environments/environment';
import { CustomDateAdapter } from '@services/custom-date-adapter';
import { BehaviorSubject, from, Subject, Subscription, takeUntil } from 'rxjs';
import { CustomCalendarHeaderComponent } from '../custom-calendar-header/custom-calendar-header.component';
import { SelectDateTimeService } from './../select-date-time.service';

export const MY_FORMATS = {
    parse: {
        dateInput: 'ddd, MMM D, YYYY',
    },
    display: {
        dateInput: 'ddd, MMM D, YYYY',
        monthYearLabel: 'MMM YYYY',
    },
};

enum CalendarMoveDirection {
    FORWARD,
    BACK,
}

@Component({
    selector: 'app-time-step-date-picker',
    templateUrl: './time-step-date-picker.component.html',
    styleUrls: ['./time-step-date-picker.component.scss'],
    providers: [
        SelectDateTimeService,
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class TimeStepDatePickerComponent implements OnInit, OnChanges, OnDestroy {
    public formGroup!: FormGroup;
    @Input() selectedDate!: Date;
    @Input() isLoading!: boolean;
    @Input() filterDates!: (arg: any) => boolean;
    @Output() nextMonthClicked: EventEmitter<any> = new EventEmitter();
    @Output() previousMonthClicked: EventEmitter<any> = new EventEmitter();
    @Output() fetchAvailabilities: EventEmitter<any> = new EventEmitter();
    @Output() updateCurrent: EventEmitter<any> = new EventEmitter();
    @Output() setStepValidState: EventEmitter<any> = new EventEmitter();
    @Output() toggleIsLoading: EventEmitter<any> = new EventEmitter();
    @Output() setNewAvailabilityTimes: EventEmitter<any> = new EventEmitter();
    @Input() availabilities!: BehaviorSubject<Availability[]>;
    @Input() minDate!: Date;
    public customCalendarHeaderComponent = CustomCalendarHeaderComponent;
    private subscriptions: Subscription[] = [];
    private _destroyed$: Subject<void>;
    private chevronSearchRecursionCounter = 0;

    constructor(
        private formBuilder: FormBuilder,
        private selectDateTimeService: SelectDateTimeService,
        private availabilitiesService: AvailabilitiesService,
        private localStorageService: LocalStorageService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this._destroyed$ = new Subject();
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            picker: this.selectedDate,
        });
        this.subscriptions.push(
            this.selectDateTimeService.nextMonthClicked.subscribe(() => {
                this.nextMonthClicked.emit();
            })
        );
        this.subscriptions.push(
            this.selectDateTimeService.previousMonthClicked.subscribe(() => {
                this.previousMonthClicked.emit();
            })
        );
        this.setNewAvailabilityTimes.emit();
        this.setStepValidState.emit();
    }

    /**
     * Add/remove the spinner on the popup during the api call
     */
    ngOnChanges(changes: SimpleChanges) {
        const calendarContent = document.getElementsByClassName('mat-calendar-content')[0] as HTMLElement;
        const overlayContainer = document.getElementsByClassName('cdk-overlay-container')[0] as HTMLElement;
        const overlayPane = document.getElementsByClassName('cdk-overlay-pane')[0] as HTMLElement;
        const overlayBackdrop = document.getElementsByClassName('cdk-overlay-backdrop')[0] as HTMLElement;

        if (changes.isLoading && calendarContent && changes.isLoading.currentValue === true) {
            this.addSpinnerElements(calendarContent, overlayContainer, overlayPane, overlayBackdrop);
        } else {
            this.removeSpinnerElements(calendarContent, overlayContainer, overlayPane, overlayBackdrop);
        }
    }

    /**
     * Add css classes to show the spinner on the popup during the api call
     */
    private addSpinnerElements(
        calendarContent: HTMLElement,
        overlayContainer: HTMLElement,
        overlayPane: HTMLElement,
        overlayBackdrop: HTMLElement
    ) {
        this.changeDetectorRef.detectChanges();
        const calendarTable = document.getElementsByClassName('mat-calendar-table')[0] as HTMLElement;
        const divNode = document.createElement('span');
        divNode.classList.add('calendar-spinner');

        if (calendarTable) {
            calendarTable.style.display = 'none';
        }
        calendarContent.classList.add('calendar-content-spinner');
        if (calendarContent.childElementCount === 1) {
            calendarContent.appendChild(divNode);
        }
        if (overlayContainer) {
            overlayContainer.classList.add('disable-click');
        }
        if (overlayPane) {
            overlayPane.classList.add('disable-click');
        }
        if (overlayBackdrop) {
            overlayBackdrop.classList.add('disable-click');
        }
    }

    /**
     * Remove css classes for the spinner on the popup when api call is finished
     */
    private removeSpinnerElements(
        calendarContent: HTMLElement,
        overlayContainer: HTMLElement,
        overlayPane: HTMLElement,
        overlayBackdrop: HTMLElement
    ) {
        const calendarTable = document.getElementsByClassName('mat-calendar-table')[0] as HTMLElement;

        if (calendarTable) {
            calendarTable.style.display = 'table';
        }
        if (calendarContent && calendarContent.classList.contains('calendar-content-spinner')) {
            calendarContent.classList.remove('calendar-content-spinner');
        }
        if (overlayContainer) {
            overlayContainer.classList.remove('disable-click');
        }
        if (overlayPane) {
            overlayPane.classList.remove('disable-click');
        }
        if (overlayBackdrop) {
            overlayBackdrop.classList.remove('disable-click');
        }
        this.selectDateTimeService.calendarDataChanged();
    }

    /**
     * Handles the click on the calendar icon (popup)
     */
    public onCalendarOpen() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        const selectedDateAsString = this.selectDateTimeService.getDateString(new Date(appointmentRequest.datetime));
        //If the month that needs to open is not cached fetch the data from the API by calling the parent method
        if (
            !this.availabilities ||
            !this.availabilities
                .getValue()
                .filter((a) => a.start.includes(selectedDateAsString.split('T')[0].substring(0, selectedDateAsString.lastIndexOf('-'))))
                .length
        ) {
            const endDate = this.selectDateTimeService.getEndOfMonth(
                this.selectDateTimeService.getNextMonth(new Date(selectedDateAsString))
            );
            this.fetchAvailabilities.emit({
                endDate: endDate,
                startDate: this.selectDateTimeService.getPreviousMonth(endDate),
            });
            this.selectDateTimeService.calendarDataChanged();
        }
    }

    /**
     * Handles the right chevron click (next day),
     * on the datepicker itself, NOT on the opened calendar popup
     */
    public onNextClicked() {
        this.recursivelyMoveThroughCalendar(CalendarMoveDirection.FORWARD);
        this.setStepValidState.emit();
    }

    /**
     * Handles the left chevron click (previous day),
     * on the datepicker itself, NOT on the opened calendar popup
     */
    public onPreviousClicked() {
        this.recursivelyMoveThroughCalendar(CalendarMoveDirection.BACK);
        this.setStepValidState.emit();
    }

    /**
     * Handles the event generated by picking a new date on the calendar popup by updating the picker value and
     * updating the parents current selected date
     * @param event Datepicker date changed event
     */
    public onDateChange(event: any) {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        const pickedDate = new Date(event.value);
        if (appointmentRequest && appointmentRequest.datetime) {
            const tempDateTime = new Date(appointmentRequest.datetime);
            pickedDate.setHours(tempDateTime.getHours());
            pickedDate.setMinutes(tempDateTime.getMinutes());
            pickedDate.setSeconds(0);
            pickedDate.setMilliseconds(0);
        }

        this.updatePickerValue(pickedDate);
        this.updateCurrent.emit({ current: pickedDate });
        this.setNewAvailabilityTimes.emit();
        this.setStepValidState.emit();
    }

    /**
     * Enables or disables the left (previous day) chevron.
     * @returns True if the chevron is to be enabled or false if the chevron is to be disabled
     */
    public previousEnabled() {
        const tempDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate());
        const now = new Date();
        const nowPlusOneMonth = this.selectDateTimeService.getDateString(new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()));
        const nowAsString = this.selectDateTimeService.getDateString(now);
        return (
            tempDate.setDate(tempDate.getDate() - 1) < new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() ||
            (!this.availabilities.getValue().filter((a) => a.start.includes(nowAsString.split('T')[0])).length &&
                this.availabilities
                    .getValue()[0]
                    .start.split('T')[0]
                    .substring(0, this.availabilities.getValue()[0].start.lastIndexOf('-')) ===
                    nowPlusOneMonth.split('T')[0].substring(0, nowAsString.lastIndexOf('-')) &&
                this.availabilities.getValue()[0].start.split('T')[0] ===
                    this.selectDateTimeService.getDateString(this.selectedDate).split('T')[0])
        );
    }

    /**
     * Enables or disables the spinner on datepicker chevrons (depending on the users calendar movement direction)
     * @param calendarMoveDirection The direction in which the user is moving (Forward/Back)
     * @param loadingStatus Indicates whether the spinner should be enabled or disabled
     */
    private toggleLoading(loadingStatus: boolean) {
        this.toggleIsLoading.emit({ isLoading: loadingStatus });
    }

    /**
     * Checks the cached availabilities for an availability thats on a specific date
     * @param date The date for which an availability is requested
     * @returns The found availability or undefined if the availability is not found
     */
    private isDateInCache(date: Date) {
        return this.availabilities.getValue().find((a) => a.start.includes(this.selectDateTimeService.getDateString(date).split('T')[0]));
    }

    private recursivelyMoveThroughCalendar(calendarMoveDirection: CalendarMoveDirection) {
        //The chevronSearchRecursionCounter is a failsafe counter that will terminate the recursion if there is need
        this.chevronSearchRecursionCounter++;
        if (this.chevronSearchRecursionCounter === environment.calendarAvailabilitySearchThreshold) {
            this.chevronSearchRecursionCounter = 0;
            return;
        }
        const selectedDateBeforeMove = new Date(this.selectedDate);
        //Update the selectedDate based on the direction (Forward/Back)
        if (this.chevronSearchRecursionCounter === 1) {
            this.toggleLoading(true);
            this.updateSelectedDate(calendarMoveDirection);
        }

        //Check if the new selectedDate is in the cache (see function for explanation). For this operation we need to pass in
        //an upper and lower bound just to make sure that the recursive search does not go beyond that
        const upperMonthSearchLimit = new Date(new Date(selectedDateBeforeMove).setMonth(selectedDateBeforeMove.getMonth() + 2));
        const lowerMonthSearchLimit = new Date(new Date(selectedDateBeforeMove).setMonth(selectedDateBeforeMove.getMonth() - 2));
        const foundDate = this.recursiveSearchForDateInCache(calendarMoveDirection, upperMonthSearchLimit, lowerMonthSearchLimit);

        //The date is found so the picker is updated, the loader is disabled,
        //the recursion count is reset and the parent component current date is updated to match the found date
        if (foundDate) {
            this.updatePickerValue(this.selectedDate);
            this.toggleLoading(false);
            this.chevronSearchRecursionCounter = 0;
            this.selectDateTimeService.calendarDataChanged();
            this.updateCurrent.emit({ current: new Date(this.selectedDate) });
            this.setNewAvailabilityTimes.emit();
        } else {
            //If the date is not found a call should be made to the API with new start and end dates and then a new search should be done with the returned data
            const tempDate = new Date();
            let startDate = this.selectDateTimeService.getPreviousMonth(this.selectDateTimeService.getNextMonth(this.selectedDate));
            if (this.selectedDate.getMonth() === tempDate.getMonth() && this.selectedDate.getFullYear() === tempDate.getFullYear()) {
                startDate = tempDate;
            }
            from(
                this.availabilitiesService.getAvailabilities(
                    this.selectDateTimeService.getDateString(startDate),
                    this.selectDateTimeService.getDateString(
                        this.selectDateTimeService.getEndOfMonth(this.selectDateTimeService.getNextMonth(this.selectedDate))
                    )
                )
            )
                .pipe(takeUntil(this._destroyed$))
                .subscribe((avaibilities) => {
                    this.availabilities.next(avaibilities);
                    this.isLoading = false;
                    //Once the availabilities are loaded we recursively call the function to find the next available date
                    this.recursivelyMoveThroughCalendar(calendarMoveDirection);
                    //Update the current selected value of the parent calendar (previous 'page' in the UI) so that everything is in sync
                    this.updateCurrent.emit({ current: new Date(this.selectedDate) });
                    this.selectDateTimeService.calendarDataChanged();
                    this.setNewAvailabilityTimes.emit();
                    this.toggleLoading(false);
                });
        }
    }

    private recursiveSearchForDateInCache(
        calendarMoveDirection: CalendarMoveDirection,
        upperMonthSearchLimit: Date,
        lowerMonthSearchLimit: Date
    ): Availability | undefined {
        const currentMont = this.selectedDate.getMonth();

        //Recursion termination condition (failsafe condition)
        if (currentMont === upperMonthSearchLimit.getMonth() || currentMont === lowerMonthSearchLimit.getMonth()) {
            return undefined;
        }

        //Try to find the selectedDate month in the cache
        const isSelectedMonthInCache = this.availabilities
            .getValue()
            .find((a) =>
                a.start.includes(
                    this.selectDateTimeService
                        .getDateString(this.selectedDate)
                        .split('T')[0]
                        .substring(0, this.selectDateTimeService.getDateString(this.selectedDate).lastIndexOf('-'))
                )
            );

        //If the selectedDate month is in the cache try to search for the date
        if (isSelectedMonthInCache) {
            const isSelectedDateInCache = this.isDateInCache(this.selectedDate);
            //If the date is found update the picker value, disable the loader on the chevron and return the found date
            if (isSelectedDateInCache) {
                this.updatePickerValue(this.selectedDate);
                this.toggleLoading(false);
                return isSelectedDateInCache;
            }
            //If the date is not in the cache move to the next date (depending on the direction) and recursively call the function to continue the search
            this.updateSelectedDate(calendarMoveDirection);
            return this.recursiveSearchForDateInCache(calendarMoveDirection, upperMonthSearchLimit, lowerMonthSearchLimit);
        } else {
            //If the month is not in the cache return undefined so that the calling function can fetch the data from the API
            return undefined;
        }
    }

    /**
     * Moves to the next date depending on the users movement direction
     * @param calendarMoveDirection Direction of the calendar movement
     */
    private updateSelectedDate(calendarMoveDirection: CalendarMoveDirection) {
        switch (calendarMoveDirection) {
            case CalendarMoveDirection.FORWARD:
                this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() + 1));
                break;
            case CalendarMoveDirection.BACK:
                this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - 1));
                break;
        }
    }

    /**
     * Updates the datepickers picked value
     * @param date The new selected date
     */
    private updatePickerValue(date: Date) {
        this.selectedDate = date;
        this.formGroup.patchValue({
            picker: date,
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.subscriptions = [];
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
