import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonName } from '@app/components/content/content.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Pet } from '@app/models/pet.model';
import { PetBreed, PetType } from '@app/models/petType.model';
import { APPOINTMENT_REQUEST, LocalStorageService } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { PetService } from '@app/services/pet.service';
import { from, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-pet-information',
    templateUrl: './pet-information.component.html',
    styleUrls: ['./pet-information.component.scss'],
})
export class PetInformationComponent implements OnInit, OnDestroy {
    public ngUnsubscribe: Subject<any> = new Subject();
    private navigationButtonSubscription!: Subscription;
    public formGroup: FormGroup;
    petTypeList: PetType[] = [];
    petBreedList: PetBreed[] = [];
    private _destroyed$: Subject<void>;

    @Input() disabled: boolean = false;
    constructor(
        private petService: PetService,
        private fb: FormBuilder,
        private navigationService: NavigationService,
        private localStorageService: LocalStorageService
    ) {
        this._destroyed$ = new Subject();
        this.formGroup = this.fb.group({
            name: new FormControl(null, [Validators.required]),
            petType: new FormControl(null, Validators.required),
            petBreed: new FormControl(null),
            weight: new FormControl(null, [Validators.required]),
        });
    }

    ngOnInit() {
        this.fetchPetTypes();
        this.navigationButtonSubscription = this.navigationService.getNavigationButtonName().subscribe((buttonName) => {
            if (buttonName === ButtonName.ReviewDetails) {
                const pet = new Pet(this.formGroup.value);
                this.localStorageService.setNested(APPOINTMENT_REQUEST, 'petInfo', pet);
            }
        });
        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            if (status === 'VALID') {
                this.navigationService.setIsStepFormValid(true);
            } else if (status === 'INVALID') {
                this.navigationService.setIsStepFormValid(false);
            }
        });
        this.previouslySelectedPetInfo();
    }

    private previouslySelectedPetInfo() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        const petInfo = appointmentRequest.petInfo;
        if (petInfo) {
            const petType = petInfo.petType;
            if (petType.petBreedList && petType.petBreedList.length > 0) {
                this.petBreedList = petType.petBreedList;
                this.formGroup.get('petBreed')!.addValidators(Validators.required);
            } else {
                this.petBreedList = [];
            }
            this.formGroup.setValue({
                name: petInfo.name,
                petType: petInfo.petType,
                petBreed: this.petBreedList.length > 0 ? petInfo.petBreed : null,
                weight: petInfo.weight,
            });
        } else {
            this.formGroup.reset();
        }
    }

    /** Need this to have a default selection for mat-select */
    public comparePetTypeOptions(p1: PetType, p2: PetType): boolean {
        return p1 && p2 && p1.name === p2.name;
    }
    public comparePetBreedOptions(p1: PetBreed, p2: PetBreed): boolean {
        return p1 && p2 && p1.name === p2.name;
    }

    private fetchPetTypes() {
        from(this.petService.getPetTypeList())
            .pipe(takeUntil(this._destroyed$))
            .subscribe((petTypeList) => {
                this.petTypeList = petTypeList;
                this.formGroup.updateValueAndValidity();
            });
    }

    public petTypeChange() {
        const petType: PetType = this.formGroup.get('petType')!.value;
        this.petBreedList = petType.petBreedList;
        const petBreed = this.formGroup.get('petBreed');
        petBreed!.reset();
        if (petType.petBreedList.length > 0) {
            petBreed!.addValidators(Validators.required);
        } else {
            petBreed!.removeValidators(Validators.required);
        }
        petBreed!.updateValueAndValidity();
    }

    ngOnDestroy() {
        this.navigationButtonSubscription.unsubscribe();
        this.ngUnsubscribe.complete();
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
