<div class="confirmation-box">
    <div class="animation-container">
        <ng-lottie class="animation" [options]="animationOptions"></ng-lottie>
    </div>
    <h1 qa="appointmentRequestSubmitted" class="message message--main">Your appointment has been requested!</h1>
    <h2 class="message message--secondary">
        Thank you <strong class="text-bold">{{ clientName }}</strong
        >! Someone will reach out to you by email to confirm the appointment.
    </h2>
    <div class="confirmation-details-container">
        <div class="confirmation-details-row">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.2666 3C11.6416 3 12.7666 4.125 12.7666 5.5C12.7666 6.875 11.6416 8 10.2666 8C8.8916
                    8 7.7666 6.875 7.7666 5.5C7.7666 4.125 8.8916 3 10.2666 3ZM10.2666 15.5C13.6416 15.5 17.5166 17.1125
                    17.7666 18H2.7666C3.0541 17.1 6.9041 15.5 10.2666 15.5ZM10.2666 0.5C7.5041 0.5 5.2666 2.7375 5.2666
                    5.5C5.2666 8.2625 7.5041 10.5 10.2666 10.5C13.0291 10.5 15.2666 8.2625 15.2666 5.5C15.2666 2.7375
                    13.0291 0.5 10.2666 0.5ZM10.2666 13C6.9291 13 0.266602 14.675 0.266602 18V20.5H20.2666V18C20.2666
                    14.675 13.6041 13 10.2666 13Z"
                    fill="#656565"
                />
            </svg>
            <span class="confirmation-details-text">{{ employeeName }}</span>
        </div>
        <div class="confirmation-details-row">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.4414 2.56609L6.2213 0.560547H6.09666C3.45781 0.565521 1.17185 2.39173 0.584218 4.96432C-0.00341779
                    7.5369 1.26291 10.1745 3.63788 11.3248V18.4632H5.09955C5.0522 19.1773 4.6172 19.8081 3.96647 20.1061L4.52168
                    21.919C5.96963 21.3657 6.95557 20.0111 7.03711 18.4632H8.53277L9.66585 11.3928L20.4301 9.87444L20.4414
                    2.56609ZM2.31142 6.22611C2.31712 4.16473 3.96699 2.4847 6.02792 2.44163L14.7073 3.66535V8.77553L6.02792
                    9.99925C3.96881 9.96227 2.31726 8.28554 2.31142 6.22611ZM7.68203 11.8913L6.91154
                    16.5709H5.52918V11.8913H7.68203ZM18.5612 8.23121L16.601 8.50315V3.97084L18.5612
                    4.24278V8.23121Z"
                    fill="#656565"
                />
            </svg>
            <span class="confirmation-details-text">{{ serviceName }}</span>
        </div>
        <div *ngIf="siteSettings && siteSettings.businessAddress" class="confirmation-details-row">
            <svg class="location-icon" width="24" height="24" viewBox="2 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.8848 12.3398C11.7848 12.3398 10.8848 11.4398 10.8848 10.3398C10.8848 9.23984 11.7848
                  8.33984 12.8848 8.33984C13.9848 8.33984 14.8848 9.23984 14.8848 10.3398C14.8848 11.4398 13.9848 12.3398 12.8848
                  12.3398ZM18.8848 10.5398C18.8848 6.90984 16.2348 4.33984 12.8848 4.33984C9.53477 4.33984 6.88477 6.90984 6.88477
                  10.5398C6.88477 12.8798 8.83477 15.9798 12.8848 19.6798C16.9348 15.9798 18.8848 12.8798 18.8848 10.5398ZM12.8848
                  2.33984C17.0848 2.33984 20.8848 5.55984 20.8848 10.5398C20.8848 13.8598 18.2148 17.7898 12.8848
                  22.3398C7.55477 17.7898 4.88477 13.8598 4.88477 10.5398C4.88477 5.55984 8.68477 2.33984 12.8848 2.33984Z"
                    fill="#656565"
                />
            </svg>
            <div class="confirmation-details-text-box">
                <span class="confirmation-details-text">{{ siteSettings.businessName }}</span>
                <span class="confirmation-details-text confirmation-details-text-sub">{{ siteSettings.businessAddress }}</span>
            </div>
        </div>
        <div class="confirmation-details-row">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.2617 2H15.2617V0H13.2617V2H5.26172V0H3.26172V2H2.26172C1.15172 2 0.271719 2.9 0.271719 4L0.261719 18C0.261719
                    19.1 1.15172 20 2.26172 20H16.2617C17.3617 20 18.2617 19.1 18.2617 18V4C18.2617 2.9 17.3617 2 16.2617 2ZM16.2617
                    18H2.26172V8H16.2617V18ZM16.2617 6H2.26172V4H16.2617V6ZM9.26172 11H14.2617V16H9.26172V11Z"
                    fill="#656565"
                />
            </svg>
            <span class="confirmation-details-text">{{ getBookingDate }}</span>
        </div>
        <div class="confirmation-details-row padding-bottom">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.2517 0C4.73172 0 0.261719 4.48 0.261719 10C0.261719 15.52 4.73172 20 10.2517 20C15.7817 20 20.2617 15.52 20.2617
                    10C20.2617 4.48 15.7817 0 10.2517 0ZM10.2617 18C5.84172 18 2.26172 14.42 2.26172 10C2.26172 5.58 5.84172 2 10.2617
                    2C14.6817 2 18.2617 5.58 18.2617 10C18.2617 14.42 14.6817 18 10.2617 18ZM10.0417 5H9.98172C9.58172 5 9.26172 5.32
                    9.26172 5.72V10.44C9.26172 10.79 9.44172 11.12 9.75172 11.3L13.9017 13.79C14.2417 13.99 14.6817 13.89 14.8817
                    13.55C15.0917 13.21 14.9817 12.76 14.6317 12.56L10.7617 10.26V5.72C10.7617 5.32 10.4417 5 10.0417 5Z"
                    fill="#656565"
                />
            </svg>
            <span class="confirmation-details-text">{{ getBookingTime }}</span>
        </div>
        <div class="confirmation-details-row flex-direction">
            <span class="confirmation-details-text">Your confirmation number is: </span>
            <span class="text-bold text-bold--colored">{{ response?.ticketID }}</span>
        </div>
    </div>
    <button id="forward-button" mat-button class="primary" name="requestNewAppointment" (click)="requestNewAppointment()">
        Request New Appointment
    </button>
    <h2 class="message message--secondary message--secondary--phone-message">
        {{ changeRequestText }}
        <strong class="text-bold text-bold--colored text-bold--colored--underlined"
            ><a *ngIf="phoneNumberExists" href="tel:{{ siteSettings.businessPhoneNumber }}">
                {{ siteSettings.businessPhoneNumber }}
            </a></strong
        >
    </h2>
</div>
