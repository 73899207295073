import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonName } from '@app/components/content/content.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { Client } from '@app/models/client.model';
import { APPOINTMENT_REQUEST, LocalStorageService, SUPPORTS_PETS } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-enter-your-information',
    templateUrl: './enter-your-information.component.html',
    styleUrls: ['./enter-your-information.component.scss'],
})
export class EnterYourInformationComponent implements OnInit, OnDestroy {
    @Input() disabled: boolean = false;
    public ngUnsubscribe: Subject<any> = new Subject();
    public supportsPets: boolean = false;
    public formGroup: FormGroup;
    public keyDecimal: boolean = false;
    public emailErrorMessage = '';

    private navigationButtonSubscription!: Subscription;

    constructor(private fb: FormBuilder, private navigationService: NavigationService, private localStorageService: LocalStorageService) {
        this.supportsPets = this.localStorageService.get(SUPPORTS_PETS) as unknown as boolean;
        this.formGroup = this.fb.group({
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.pattern(/^[^@]+@[A-Za-z]+\.[A-Za-z]+$/)]),
            phoneNumber: new FormControl('', [Validators.required, Validators.pattern('[- 0-9()]+')]),
            enableEmailMarketing: new FormControl(true),
            enableTextMarketing: new FormControl(true),
        });
    }

    ngOnInit() {
        this.navigationButtonSubscription = this.navigationService.getNavigationButtonName().subscribe((buttonName) => {
            if (
                (buttonName === ButtonName.PetInformation && this.supportsPets) ||
                (buttonName === ButtonName.ReviewDetails && !this.supportsPets)
            ) {
                const client = new Client(this.formGroup.value);
                this.localStorageService.setNested(APPOINTMENT_REQUEST, 'client', client);
            }
        });
        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            if (status === 'VALID') {
                this.navigationService.setIsStepFormValid(true);
            } else if (status === 'INVALID') {
                this.navigationService.setIsStepFormValid(false);
            }
        });
        this.previouslyAddedClientInfo();
    }

    showEmailErrorMessage() {
        const email: string = this.formGroup.controls['email'].value;
        if (!/^[^@]+@[A-Za-z]+\.[A-Za-z]+$/.test(email)) {
            this.emailErrorMessage = 'Please enter a valid email';
        } else if (email) {
            this.emailErrorMessage = '';
        }
    }

    private previouslyAddedClientInfo() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        if (appointmentRequest.client) {
            this.formGroup.setValue({
                firstName: appointmentRequest.client.firstName,
                lastName: appointmentRequest.client.lastName,
                email: appointmentRequest.client.email,
                phoneNumber: appointmentRequest.client.phoneNumber,
                enableEmailMarketing: appointmentRequest.client.enableEmailMarketing,
                enableTextMarketing: appointmentRequest.client.enableTextMarketing,
            });
        } else {
            this.navigationService.setIsStepFormValid(false);
        }
    }

    ngOnDestroy() {
        this.navigationButtonSubscription.unsubscribe();
        this.ngUnsubscribe.complete();
    }
}
