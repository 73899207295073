import { Injectable } from '@angular/core';
import { ServiceApiService } from '@api-services/service-api.service';

@Injectable({
    providedIn: 'root',
})
export class ServiceService {
    constructor(private serviceApiService: ServiceApiService) {}

    public getServiceList() {
        return this.serviceApiService.getServiceList();
    }
}
