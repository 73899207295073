/// <reference path='../../../types/redux-offline/index.d.ts' />

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { CognitoSyncClient, ListRecordsCommand, UpdateRecordsCommand } from '@aws-sdk/client-cognito-sync';
import { CognitoIdentityCredentials, fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { environment } from '@environments/environment';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { ErrorResponse, onError } from 'apollo-link-error';
import { AUTH_TYPE, AWSAppSyncClient, createAppSyncLink } from 'aws-appsync';
import { CUSTOMER_ID, LocalStorageService } from './local-storage.service';
import { NewRelicLoggingService } from './new-relic-logging.service';

@Injectable({ providedIn: 'root' })
export class AppSyncService {
    private clients: { [serviceUrl: string]: AWSAppSyncClient<NormalizedCacheObject> } = {};
    private region: string;
    private identityPoolID: string;
    private datasetName = 'CustomerData';
    private customerIdKey = 'customerID';
    private customerID!: string;
    private fileNameForNewRelic = 'app-sync.service.ts';

    constructor(
        private localStorageService: LocalStorageService,
        private router: Router,
        private newRelicLoggingService: NewRelicLoggingService
    ) {
        this.region = environment.region;
        this.identityPoolID = environment.identityPoolID;
    }
    public getClient(serviceUrl: string): AWSAppSyncClient<NormalizedCacheObject> {
        let client: AWSAppSyncClient<NormalizedCacheObject> = this.clients[serviceUrl];

        if (!client) {
            client = this.createClient(serviceUrl);
            this.clients[serviceUrl] = client;
        }

        return client;
    }

    private createClient(serviceUrl: string): AWSAppSyncClient<NormalizedCacheObject> {
        const errorLink: ApolloLink = onError((args) => this.handleError(args));
        const appSyncLink: ApolloLink = createAppSyncLink({
            url: serviceUrl,
            region: this.region,
            auth: {
                type: AUTH_TYPE.AWS_IAM,
                credentials: async () => {
                    return this.getCredentials();
                },
            },
            complexObjectsCredentials: () => null,
        });

        return new AWSAppSyncClient({ disableOffline: true } as any, { link: ApolloLink.from([errorLink, appSyncLink]) });
    }

    private handleError(args: ErrorResponse) {
        if (args.graphQLErrors?.length) {
            args.graphQLErrors.map((error) => {
                const errorMsg = `Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`;
                console.error(`[GraphQL error]: ${errorMsg}`);
                this.newRelicLoggingService.logError(error, this.fileNameForNewRelic);
            });
        }

        //If this point is reached, the error will just bubble up to the caller as it would normally
        this.router.navigate(['/booking/error'], {
            queryParams: {
                DSID: this.customerID,
            },
        });
    }
    private async getCredentials(): Promise<CognitoIdentityCredentials> {
        const cognitoCredentials = fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: this.region }),
            identityPoolId: this.identityPoolID,
        });
        const credentials = await cognitoCredentials();
        await this.setIdentityData(credentials);
        return credentials;
    }

    private async setIdentityData(credentials: CognitoIdentityCredentials) {
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
        const cognitoSyncClient = new CognitoSyncClient({
            region: this.region,
            credentials: credentials,
        });
        const listRecordsCommand = new ListRecordsCommand({
            DatasetName: this.datasetName,
            IdentityId: credentials.identityId,
            IdentityPoolId: this.identityPoolID,
        });

        const response = await cognitoSyncClient.send(listRecordsCommand);

        const customerIdIsDifferent =
            response?.Records && response?.Records?.some((r) => r.Key === this.customerIdKey && r.Value !== `${this.customerID}`);
        const recordNotExist = response?.DatasetExists;
        if (!recordNotExist || customerIdIsDifferent) {
            const updateRecordsCommand = new UpdateRecordsCommand({
                DatasetName: this.datasetName,
                IdentityId: credentials.identityId,
                IdentityPoolId: this.identityPoolID,
                SyncSessionToken: response.SyncSessionToken,
                RecordPatches: [
                    {
                        Key: this.customerIdKey,
                        Value: this.customerID,
                        Op: 'replace',
                        SyncCount: response.DatasetSyncCount,
                    },
                ],
            });
            const syncResponse = await cognitoSyncClient.send(updateRecordsCommand);
            return syncResponse;
        }
    }
}
