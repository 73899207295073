import { AppSyncResponse } from '@helpers/appSyncResponse.helper';
import { ApolloQueryResult } from 'apollo-client';

export class Service {
    public id: string;
    public name: string;
    public categoryList: string[];
    public employeeIDList?: string[];
    public resourceIDList?: string[];
    public duration: number;

    constructor(service: Service) {
        this.id = service.id;
        this.name = service.name;
        this.categoryList = service.categoryList;
        this.employeeIDList = service.employeeIDList;
        this.resourceIDList = service.resourceIDList;
        this.duration = service.duration;
    }

    public static deserializeAppSyncResponse(appSyncResponse: ApolloQueryResult<AppSyncResponse>): Service[] {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: Service[]) => obj.map((e: Service) => e), [
            'getServiceList',
            'serviceList',
        ]);
    }
}
