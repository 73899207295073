import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { convertTo12Hour, formatDateOutput } from '@app/shared/helpers/extensions';
import { AnimationOptions } from 'ngx-lottie';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnDestroy, OnInit {
    @HostBinding('class') classes = 'confirmation-component-container';
    public ngUnsubscribe: Subject<any> = new Subject();
    response: any;
    formattedDate: string = '';
    formattedTime: string = '';
    employeeName: string = '';
    serviceName: string = '';
    clientName: string = '';
    changeRequestText = 'To change your request call:';
    phoneNumberExists = false;

    siteSettings!: SiteBookingSettings;
    customerID!: string;
    constructor(private localStorageService: LocalStorageService, private router: Router) {
        const navigation = this.router.getCurrentNavigation();
        if (navigation) {
            this.response = navigation.extras.state?.response;
        } else {
            throw new Error('Navigation is null');
        }
    }
    public animationOptions: AnimationOptions = {
        path: '../../../assets/appointment-submitted.json',
        loop: false,
    };
    ngOnInit() {
        const appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        this.employeeName = appointmentRequest.employee[0].name;
        this.serviceName = appointmentRequest.service[0].name;
        this.clientName = this.response?.client.firstName + ' ' + this.response?.client.lastName;
        this.localStorageService.remove(APPOINTMENT_REQUEST);
        this.getDataFromLocalStorage();
        if (this.siteSettings.businessPhoneNumber) {
            this.phoneNumberExists = true;
        } else {
            this.changeRequestText = this.changeRequestText.replace(':', ' the business.');
        }
    }
    get getBookingDate() {
        return formatDateOutput(new Date(this.response?.startDateTime));
    }

    get getBookingTime() {
        const tempDate = new Date(this.response?.startDateTime);
        const hours = tempDate.getHours().toString().padStart(2, '0');
        const minutes = tempDate.getMinutes().toString().padStart(2, '0');
        const seconds = tempDate.getSeconds().toString().padStart(2, '0');
        const milliseconds = tempDate.getMilliseconds().toString().padStart(3, '0');

        return convertTo12Hour(`${hours}:${minutes}:${seconds}.${milliseconds}`, true);
    }
    requestNewAppointment() {
        this.router.navigate(['/booking/service'], {
            queryParams: {
                DSID: this.customerID,
            },
        });
    }

    private getDataFromLocalStorage() {
        this.siteSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
    }
    ngOnDestroy() {
        this.ngUnsubscribe.complete();
    }
}
