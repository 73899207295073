<!-- eslint-disable max-len -->
<div class="step-container">
    <div class="header-container">
        <h1 qa="selectDateTimeTitle" class="step-header-main">
            {{ dateTimeScreen === DateTimeScreen.TimeScreen ? 'Select Time' : 'Select Date' }}
        </h1>
    </div>
    <ng-container *ngIf="dateTimeScreen === DateTimeScreen.DateScreen">
        <div class="form-container">
            <mat-calendar
                *ngIf="!isLoading"
                [headerComponent]="customCalendarHeaderComponent"
                [startAt]="current"
                [minDate]="minDate"
                [dateFilter]="filterDates"
                [(selected)]="selected"
            >
            </mat-calendar>
        </div>
    </ng-container>
    <ng-container *ngIf="dateTimeScreen === DateTimeScreen.TimeScreen">
        <app-time-step-date-picker
            class="center-datepicker"
            [selectedDate]="selected"
            [filterDates]="filterDates"
            [availabilities]="availabilities"
            (nextMonthClicked)="nextMonthClicked()"
            (previousMonthClicked)="previousMonthClicked()"
            [isLoading]="isLoading"
            (fetchAvailabilities)="fetchAvailabilities($event)"
            [minDate]="minDate"
            (updateCurrent)="updateCurrent($event)"
            (setNewAvailabilityTimes)="setNewAvailabilityTimes()"
            (toggleIsLoading)="toggleIsLoading($event)"
            (setStepValidState)="setStepValidState()"
        >
        </app-time-step-date-picker>
        <div class="time-container" *ngIf="!isLoading">
            <h2 class="time-header" *ngIf="morningTimeSlots.length">Morning</h2>
            <div class="time-slot-box">
                <ng-container *ngFor="let timeSlot of morningTimeSlots; let i = index">
                    <a qa="time" class="time-slot" id="ts-morning-{{ i }}" (click)="onFocus('ts-morning-', i)">{{
                        timeSlotCreate(timeSlot)
                    }}</a>
                </ng-container>
            </div>
        </div>

        <div class="time-container" *ngIf="!isLoading">
            <h2 class="time-header margin-top-20" *ngIf="afternoonTimeSlots.length">Afternoon</h2>
            <div class="time-slot-box">
                <ng-container *ngFor="let timeSlot of afternoonTimeSlots; let i = index">
                    <a qa="time" class="time-slot" id="ts-afternoon-{{ i }}" (click)="onFocus('ts-afternoon-', i)">{{
                        timeSlotCreate(timeSlot)
                    }}</a>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
