import { Subject } from 'rxjs';

export class SelectDateTimeService {
    public nextMonthClicked: Subject<void> = new Subject();
    public previousMonthClicked: Subject<void> = new Subject();
    public onCalendarDataChanged: Subject<void> = new Subject();

    public nextClicked() {
        this.nextMonthClicked.next();
    }

    public previousClicked() {
        this.previousMonthClicked.next();
    }

    public calendarDataChanged() {
        this.onCalendarDataChanged.next();
    }

    public getEndOfMonth = (date: Date) => {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    };

    public getNextMonth = (currentDate: Date) => {
        return currentDate.getMonth() === 11
            ? new Date(currentDate.getFullYear() + 1, 0, 1)
            : new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    };

    public getPreviousMonth = (currentDate: Date) => {
        return currentDate.getMonth() === 0
            ? new Date(currentDate.getFullYear() - 1, 11, 1)
            : new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    };

    getDateString(date: Date) {
        const pad = function (num: number) {
            return (num < 10 ? '0' : '') + num;
        };

        return (
            date.getFullYear() +
            '-' +
            pad(date.getMonth() + 1) +
            '-' +
            pad(date.getDate()) +
            'T' +
            pad(date.getHours()) +
            ':' +
            pad(date.getMinutes()) +
            ':' +
            pad(date.getSeconds()) +
            '.' +
            date.getMilliseconds()
        );
    }
}
