import { Component } from '@angular/core';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { LocalStorageService, SITE_BOOKING_SETTINGS, CUSTOMER_ID } from '@app/services/local-storage.service';

@Component({
    selector: 'app-title-bar',
    templateUrl: './title-bar.component.html',
    styleUrls: ['./title-bar.component.scss'],
})
export class TitleBarComponent {
    businessName: string;
    customerID: string | undefined;
    constructor(private localStorageService: LocalStorageService) {
        const siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        this.businessName = siteBookingSettings?.businessName || 'Business Name';
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
    }
}
