<div *ngIf="!disabled" class="step-container">
    <div class="header-container">
        <h1 qa="clientAndPetInformationTitle" class="step-header-main">
            {{ supportsPets ? 'Client & Pet Information' : 'Client information' }}
            <span *ngIf="supportsPets" class="mini-step-header"> 1 of 2</span>
        </h1>
        <h2 class="step-header-secondary">All fields are required.</h2>
    </div>
    <form class="form-container" [formGroup]="formGroup">
        <div class="form-field">
            <label>
                First Name
                <span>*</span>
            </label>
            <mat-form-field>
                <input matInput qa="firstName" name="firstName" autocomplete="turnoff" formControlName="firstName" maxlength="20" />
            </mat-form-field>
        </div>
        <div class="form-field">
            <label>
                Last Name
                <span>*</span>
            </label>
            <mat-form-field>
                <input matInput qa="lastName" name="lastName" autocomplete="turnoff" formControlName="lastName" maxlength="20" />
            </mat-form-field>
        </div>
        <div class="form-field" [ngClass]="{ 'error-message-margin': emailErrorMessage }">
            <label>
                Email
                <span>*</span>
            </label>
            <mat-form-field [floatLabel]="'never'">
                <input
                    placeholder="email@address.com"
                    matInput
                    type="email"
                    name="email"
                    formControlName="email"
                    autocomplete="turnoff"
                    maxlength="60"
                    (ngModelChange)="showEmailErrorMessage()"
                    qa="email"
                />
            </mat-form-field>
            <mat-error *ngIf="emailErrorMessage" class="email-error-message">{{ emailErrorMessage }}</mat-error>
        </div>
        <div class="form-field">
            <label>
                Phone Number
                <span>*</span>
            </label>
            <mat-form-field [floatLabel]="'never'">
                <input
                    placeholder="(555) 555-555"
                    type="tel"
                    matInput
                    name="phoneNumber"
                    autocomplete="turnoff"
                    formControlName="phoneNumber"
                    maxlength="30"
                    qa="phoneNumber"
                />
            </mat-form-field>
        </div>
        <div class="form-field">
            <p class="updates-text">
                How you would like to receive<br class="hidden-ss" />
                updates and promotions?
            </p>
            <mat-checkbox formControlName="enableEmailMarketing">Email</mat-checkbox>
            <mat-checkbox formControlName="enableTextMarketing">Text messages</mat-checkbox>
        </div>
    </form>
</div>
