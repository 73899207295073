import { Injectable } from '@angular/core';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { SyncAppointmentRequestDataRequest, SyncAppointmentRequestDataResponse } from '@app/models/syncAppointmentRequest.model';
import { environment } from '@environments/environment';
import gql from 'graphql-tag';
import { AppSyncService } from '../app-sync.service';

import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class AppointmentRequestApiService {
    constructor(
        private appSyncService: AppSyncService,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService
    ) {}

    async createAppointmentRequest(): Promise<SyncAppointmentRequestDataResponse | undefined> {
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const apptRequestFromLocalStorage = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        const daySmartAccountID = this.localStorageService.getString(CUSTOMER_ID);

        const syncAppointmentRequestDataRequest =
            SyncAppointmentRequestDataRequest.convertLocalStorageApptRequestToSyncApptRequestDataRequest(
                apptRequestFromLocalStorage,
                daySmartAccountID
            );
        const createAppointmentRequestMutation = gql`
            mutation syncAppointmentRequest($appointmentRequest: SyncApptRequestDataRequest!) {
                syncAppointmentRequest(input: $appointmentRequest) {
                    newValue {
                        client {
                            firstName
                            lastName
                            email
                        }
                        id
                        ticketID
                        itemList {
                            employeeID
                            serviceID
                        }
                        startDateTime
                    }
                }
            }
        `;
        this.navigationService.setIsLoading(true);
        const mutationResult = await client.mutate({
            mutation: createAppointmentRequestMutation,
            variables: { appointmentRequest: syncAppointmentRequestDataRequest },
        });
        const response = AppointmentRequest.deserializeAppSyncResponse(mutationResult);
        this.navigationService.setIsLoading(false);
        return response;
    }
}
