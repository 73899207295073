export function dateToStringNoTimeZone(date: Date) {
    const year: string = date.getFullYear().toString().padStart(4, '0');
    const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
    const day: string = date.getDate().toString().padStart(2, '0');
    const hours: string = date.getHours().toString().padStart(2, '0');
    const minutes: string = date.getMinutes().toString().padStart(2, '0');
    const seconds: string = '00';
    const milliseconds: string = '000';
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function convertTo24Hour(time12h: string) {
    let hours = Number(time12h.match(/^(\d+)/)![1]);
    const minutes = Number(time12h.match(/:(\d+)/)![1]);
    const isAM = time12h.includes('AM');
    if (!isAM && hours < 12) {
        hours = hours + 12;
    }
    if (isAM && hours === 12) {
        hours = hours - 12;
    }

    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) {
        sHours = '0' + sHours;
    }

    if (minutes < 10) {
        sMinutes = '0' + sMinutes;
    }
    return sHours + ':' + sMinutes;
}

export function convertTo12Hour(time24h: any, returnOnlyHHMM: boolean) {
    if (!/^\d{2}:\d{2}:\d{2}\.\d{3}$/.test(time24h)) {
        return null;
    }

    // eslint-disable-next-line prefer-const
    let [hours, minutes, seconds] = time24h.split(':');
    const milliseconds = seconds.slice(-3);
    seconds = seconds.slice(0, -4);

    let amPm = 'AM';
    if (hours === '00') {
        hours = '12';
    } else if (hours === '12') {
        amPm = 'PM';
    } else if (hours > '12') {
        hours = (parseInt(hours) - 12).toString();
        amPm = 'PM';
    }
    hours = parseInt(hours, 10);

    if (returnOnlyHHMM) {
        return `${hours}:${minutes} ${amPm}`;
    }
    return `${hours}:${minutes}:${seconds}.${milliseconds} ${amPm}`;
}

export function formatDateOutput(date: Date) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${dayOfWeek}, ${month} ${day}, ${year}`;
}

export function extractNumbersFromString(str: string) {
    return str.replace(/[^0-9]/g, '');
}
