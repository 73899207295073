import { Injectable } from '@angular/core';
import { PetType } from '@app/models/petType.model';
import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { environment } from '@environments/environment';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { AppSyncService } from '../app-sync.service';
import { LocalDbService, LOCAL_DB_TABLE_NAME } from '../local-db.service';
import { CUSTOMER_ID, LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class PetApiService {
    constructor(
        private appSyncService: AppSyncService,
        private localDbCache: LocalDbService,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService
    ) {}

    public async getPetTypeList(): Promise<PetType[]> {
        const customerID = this.localStorageService.getString(CUSTOMER_ID);
        const petTypeList = (await this.localDbCache.get(LOCAL_DB_TABLE_NAME.petType, 'petTypeList')) as PetType[];
        if (petTypeList) {
            return petTypeList;
        }

        this.navigationService.setIsLoading(true);
        const modelList = await this.gqlCall(customerID);
        this.navigationService.setIsLoading(false);
        return modelList;
    }

    public async setPetTypeListToTheLS(daySmartAccountID: string): Promise<void> {
        const petTypeList = (await this.localDbCache.get(LOCAL_DB_TABLE_NAME.petType, 'petTypeList')) as PetType[];
        if (petTypeList) {
            return;
        }
        this.gqlCall(daySmartAccountID);
    }

    private async gqlCall(daySmartAccountID: string): Promise<PetType[]> {
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getPetTypeListQuery = gql`
            query getPetTypeList($daySmartAccountID: ID!) {
                getPetTypeList(input: { daySmartAccountID: $daySmartAccountID }) {
                    petTypeList {
                        id
                        name
                        petBreedList {
                            id
                            typeID
                            name
                        }
                    }
                }
            }
        `;
        const queryResult: ApolloQueryResult<AppSyncResponse> = await client.query({
            query: getPetTypeListQuery,
            variables: { daySmartAccountID: daySmartAccountID },
            fetchPolicy: 'no-cache',
        });
        const modelList = PetType.deserializeAppSyncResponse(queryResult);
        this.localDbCache.set(LOCAL_DB_TABLE_NAME.petType, 'petTypeList', modelList);
        return modelList;
    }
}
