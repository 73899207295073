import { Injectable } from '@angular/core';
import { AppointmentRequestApiService } from './api/appointment-request-api.service';

@Injectable({
    providedIn: 'root',
})
export class AppointmentRequestService {
    constructor(private appointmentRequestApiService: AppointmentRequestApiService) {}

    public createAppointmentRequest() {
        return this.appointmentRequestApiService.createAppointmentRequest();
    }
}
