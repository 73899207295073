import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { ApolloQueryResult } from 'apollo-client';
export class Availability {
    public employeeId: number;
    public resourceId?: number;
    public start: string;
    public end: string;

    constructor(availability: Availability) {
        this.employeeId = availability.employeeId;
        this.resourceId = availability.resourceId;
        this.start = availability.start;
        this.end = availability.end;
    }

    public static deserializeAppSyncResponse(appSyncResponse: ApolloQueryResult<AppSyncResponse>): Availability[] {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: Availability[]) => obj.map((e: Availability) => e), [
            'getAvailabilitiesList',
            'availabilitiesList',
        ]);
    }
}
