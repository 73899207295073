import { Injectable } from '@angular/core';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { SiteApiService } from './api/site-api.service';

@Injectable({
    providedIn: 'root',
})
export class SiteService {
    constructor(private siteApiService: SiteApiService) {}

    public getSiteBookingSettings(daySmartAccountID: string): Promise<SiteBookingSettings> {
        return this.siteApiService.getSiteBookingSettings(daySmartAccountID);
    }
    public getSite(daySmartAccountID: string): Promise<boolean> {
        return this.siteApiService.getSite(daySmartAccountID);
    }
}
