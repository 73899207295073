import { LocalStorageService, CUSTOMER_ID } from '@app/services/local-storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class BaseRouteGuard implements CanActivate {
    constructor(private localStorageService: LocalStorageService, private router: Router) {}

    canActivate() {
        const customerID = this.localStorageService.getString(CUSTOMER_ID);
        if (!customerID) {
            this.router.navigate(['booking/invalid']);
            return false;
        } else {
            return true;
        }
    }
}
