import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AppSyncResponse } from '@helpers/appSyncResponse.helper';
import { Employee } from '@models/employee.model';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { AppSyncService } from '../app-sync.service';
import { LocalDbService, LOCAL_DB_TABLE_NAME } from '../local-db.service';
import { CUSTOMER_ID, LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class EmployeeApiService {
    private customerID;
    constructor(
        private appSyncService: AppSyncService,
        private localDbCache: LocalDbService,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService
    ) {
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
    }

    async getEmployeeList(employeeIDList?: string[]): Promise<Employee[]> {
        const employeeList = (await this.localDbCache.get(LOCAL_DB_TABLE_NAME.employee, 'employeeList')) as Employee[];
        if (employeeList && employeeIDList?.every((eID) => employeeList.map((e) => e.id).includes(eID))) {
            return employeeList.filter((e) => employeeIDList.includes(e.id));
        }
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getEmployeeListQuery = gql`
            query getEmployeeList($daySmartAccountID: ID!, $employeeIDList: [ID]) {
                getEmployeeList(input: { daySmartAccountID: $daySmartAccountID, employeeIDList: $employeeIDList }) {
                    employeeList {
                        id
                        name
                    }
                }
            }
        `;
        this.navigationService.setIsLoading(true);
        const queryResult: ApolloQueryResult<AppSyncResponse> = await client.query({
            query: getEmployeeListQuery,
            variables: { daySmartAccountID: this.customerID, employeeIDList: employeeIDList },
            fetchPolicy: 'no-cache',
        });

        const modelList = Employee.deserializeAppSyncResponse(queryResult);
        this.localDbCache.set(LOCAL_DB_TABLE_NAME.employee, 'employeeList', modelList);
        this.navigationService.setIsLoading(false);
        return modelList;
    }
}
